<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGpath_aptitude_recoFormData && Object.keys(vmGpath_aptitude_recoFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationaptitude_name">
                    {{cvAptitudeNameLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.aptitude_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationaptitude_score">
                    {{cvAptitudeScoreLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.aptitude_score"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- score 100 50 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_category">
                    {{cvRecoCategoryLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.reco_category"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationclass">
                    {{cvClassLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.class"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- Comma separated 11,12 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_desc">
                    {{cvRecoDescLabel}}</label
                  >
                  <textarea
                    v-model="vmGpath_aptitude_recoFormData.reco_desc"
                    type="text"
                    class="form-control textarea"
                    required>
                  </textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_url">
                    {{cvRecoUrlLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.reco_url"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpath_aptitude_recoEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { gpathAptitudeRecos } from "../../../FackApi/api/GpathAptitudeReco"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "gpathAptitudeRecoEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGpath_aptitude_recoObj: {
      type: Object,
      default: function () {
        return {
          "aptitude_name": "",
          "aptitude_score": "",
          "reco_category": "",
          "class": "",
          "reco_desc": "",
          "reco_url": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit GPath Aptitude Reco",
      cvCardSubHeader: "Edit GPath Aptitude Reco",
      cvSubmitBtn: "Edit",
      cvAptitudeNameLabel: "aptitude name",
      cvAptitudeScoreLabel: "aptitude score",
      cvRecoCategoryLabel: "reco category",
      cvClassLabel: "class",
      cvRecoDescLabel: "reco desc",
      cvRecoUrlLabel: "reco url",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPath Aptitude Reco Updated",
      vmGpath_aptitude_recoFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.gpath_aptitude_recoView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGpath_aptitude_recoFormData) {
          if (!this.vmGpath_aptitude_recoFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpath_aptitude_recoView
     */
    async gpath_aptitude_recoView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGpath_aptitude_recoFormData = this.propGpath_aptitude_recoObj
        }
        else {
          let gpathAptitudeRecoId = this.$route.params.gpar_id
          let gpathAptitudeRecoViewResp = await gpathAptitudeRecos.gpath_aptitude_recoView(this, gpathAptitudeRecoId)

          if (gpathAptitudeRecoViewResp && gpathAptitudeRecoViewResp.resp_status) {
            this.vmGpath_aptitude_recoFormData = gpathAptitudeRecoViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoView() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitude_recoEdit
     */
    async gpath_aptitude_recoEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathAptitudeRecoAddResp = await gpathAptitudeRecos.gpath_aptitude_recoEdit(this, this.vmGpath_aptitude_recoFormData)
        await ApiResponse.responseMessageDisplay(this, gpathAptitudeRecoAddResp)

        if (gpathAptitudeRecoAddResp && !gpathAptitudeRecoAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGpath_aptitude_recoEditModal", this.vmGpath_aptitude_recoFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
