/*eslint-disable */
// import Gpath_aptitude_recoModel from '../../Model/Gpath_aptitude_reco'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let gpathAptitudeRecos = {
  /**
   * gpath_aptitude_recoList
   */
  async gpath_aptitude_recoList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_aptitude_reco_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_aptitude_recoList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * gpath_aptitude_recoView
   */
  async gpath_aptitude_recoView (context, gpathAptitudeRecoId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpar_id', gpathAptitudeRecoId);
      return await request.curl(context, "gpath_aptitude_reco_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_aptitude_recoView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_aptitude_recoAdd
   */
  async gpath_aptitude_recoAdd (context, gpath_aptitude_recoObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_aptitude_recoObj) {
        post_data.append(key, gpath_aptitude_recoObj[key]);
      }

      return await request.curl(context, "gpath_aptitude_reco_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_aptitude_recoAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_aptitude_recoEdit
   */
  async gpath_aptitude_recoEdit (context, gpath_aptitude_recoObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_aptitude_recoObj) {
        post_data.append(key, gpath_aptitude_recoObj[key]);
      }

      return await request.curl(context, "gpath_aptitude_reco_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_aptitude_recoEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_aptitude_recoDelete
   */
  async gpath_aptitude_recoDelete (context, gpathAptitudeRecoId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpar_id', gpathAptitudeRecoId);

      return await request.curl(context, "gpath_aptitude_reco_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_aptitude_recoDelete() and Exception:',err.message)
    }
  }
}

export {
  gpathAptitudeRecos
}
